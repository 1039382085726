<template>
  <div>
    <partners-table />
  </div>
</template>
<script>
import PartnersTable from './PartnersTable.vue'

export default {
  components: {
    PartnersTable,
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
